import { graphql, PageProps } from "gatsby";
import React from "react";
import { Layout } from "../../components/layout";
import { Box } from "../../components/rebrand/core/box";
import { HeroSection } from "../../components/sections/hero";
import { ZigZagSection } from "../../components/rebrand/sections/ZigZag/ZigZag";
import { SEO } from "../../components/seo";

import {
    PlannycsData,
    ZigZagSectionDataPlannycs
} from "../../data/products";

export const pageQuery = graphql`
  query PlannycsPage {
    site {
      siteMetadata {
        pages {
          products {
            plannycs {
                title
                description
                keywords
            }
          }
        }
      }
    }
  }
`;

interface PlannycsPageProps extends PageProps {
    data: Queries.PlannycsPageQuery; 
}


export default function ProductPlannycs({ location: { pathname }, data: { site } }: PlannycsPageProps) {

    const { title, description, keywords } = site?.siteMetadata?.pages?.products?.plannycs as Queries.SiteSiteMetadataPagesProductsPlannycs;

    const seo = {
        title: title as string,
        description: description as string,
        // keywords: keywords as [],
        pathname,
    };

    return (
        <Layout>
            <SEO {...seo} />
            <HeroSection {...PlannycsData}>
                <Box display="flex" flexDirection="column">
                    <Box height={[6, 12, 26]} />
                </Box>
            </HeroSection>
            <ZigZagSection {...ZigZagSectionDataPlannycs} />
        </Layout>
    );
}
